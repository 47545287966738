// src/plugins/formatToK.js
export default {
    install(app) {
      app.config.globalProperties.$formatToK = function (num) {
        if (num >= 1000 && num % 1000 === 0) {
          return (num / 1000) + 'k';
        }
        return num.toString();
      }
    }
  }
  