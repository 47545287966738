import { mapState, mapActions } from "vuex";
export default {
  props: ["leftIcon", "name", "isLeftIcon", "bg", "color", "centerImg", "isLeftOpen", "rightText", "rightText2", "rightUrl", "rightImg", "rightImg2", "rightUrl2"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions(["toPath"]),
    toLeftOpen() {
      this.$emit("toLeftOpen");
    },
    toRightUrl() {
      this.$emit("toRightUrl");
    },
    toRightUrl2() {
      this.$emit("toRightUrl2");
    }
  }
};