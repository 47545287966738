import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import './styles/global.css'; // 导入全局样式

// 国际化
import i18n from './locales';
const app = createApp(App);
// vant组件
import 'vant/lib/index.css'; // 引入 Vant 的样式文件
import { Lazyload } from 'vant';

import VConsole from 'vconsole';
// const vConsole = new VConsole();

// 安装pwa提示
window.addEventListener('beforeinstallprompt', function (e) {
  // 注册 sw.js
  console.log('=注册=');
  e.preventDefault();
  console.log(savePrompt);
  savePrompt = e;
});

// 1000转1k
import formatToK from './plugins/formatToK'
app.use(formatToK)
// 按钮点击反馈效果
import clickFeedback from './directives/clickFeedback';
app.directive('click-feedback', clickFeedback);

// 屏蔽“[intlify] Not found”警告
const originalConsoleWarn = console.warn;
console.warn = (message, ...optionalParams) => {
  if (message.includes("[intlify] Not found")) {
    return;
  }
  originalConsoleWarn.apply(console, [message, ...optionalParams]);
};

// 自定义组件
import NavBar from '@/components/NavBar/index.vue';
import TopBarLogin from '@/components/TopBar/TopBarLogin.vue';
import TopBar from '@/components/TopBar/index.vue';
import Empty from '@/components/Empty/index.vue';

app.component('NavBar', NavBar).component('TopBarLogin', TopBarLogin).component('TopBar', TopBar).component('Empty', Empty);
// 自定义toast
import showToast from './components/Toast/toast.js';
app.config.globalProperties.$toast = showToast;

import axios from './util/axios';
import './registerServiceWorker'
app.config.globalProperties.$axios = axios;

// 注册时可以配置额外的选项
const loadimage = require('./assets/loading_bg.png')
app.use(Lazyload, {
  loading: loadimage,
});

app.use(store)
app.use(i18n)
app.use(router);
app.mount('#app');
