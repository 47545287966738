import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '../../assets/homePage/backBtn.webp';
const _hoisted_1 = {
  class: "topBar_left"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "topBar_center"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "topBar_right"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_click_feedback = _resolveDirective("click-feedback");
  return _openBlock(), _createElementBlock("div", {
    class: "topBar flex_between f_center",
    style: _normalizeStyle(`background: ${$props.bg};color: ${$props.color}`)
  }, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [$props.isLeftOpen ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: $props.leftIcon || require('../../assets/homePage/backBtn.webp'),
    onClick: _cache[0] || (_cache[0] = $event => $options.toLeftOpen())
  }, null, 8, _hoisted_2)) : $props.isLeftIcon ? (_openBlock(), _createElementBlock("img", {
    key: 1,
    src: _imports_0,
    onClick: _cache[1] || (_cache[1] = $event => _ctx.toPath())
  })) : _createCommentVNode("", true)])), [[_directive_click_feedback]]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [$props.centerImg ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: $props.centerImg
  }, null, 8, _hoisted_4)) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($props.name), 1))])), [[_directive_click_feedback]]), _createElementVNode("div", _hoisted_6, [$props.rightText ? (_openBlock(), _createElementBlock("span", {
    key: 0,
    onClick: _cache[2] || (_cache[2] = $event => _ctx.toPath($props.rightUrl))
  }, _toDisplayString($props.rightText), 1)) : _createCommentVNode("", true), $props.rightText2 ? (_openBlock(), _createElementBlock("span", {
    key: 1,
    onClick: _cache[3] || (_cache[3] = (...args) => $options.toRightUrl && $options.toRightUrl(...args))
  }, _toDisplayString($props.rightText2), 1)) : _createCommentVNode("", true), $props.rightImg ? (_openBlock(), _createElementBlock("img", {
    key: 2,
    src: $props.rightImg,
    onClick: _cache[4] || (_cache[4] = (...args) => $options.toRightUrl && $options.toRightUrl(...args))
  }, null, 8, _hoisted_7)) : _createCommentVNode("", true), $props.rightImg2 ? (_openBlock(), _createElementBlock("img", {
    key: 3,
    src: $props.rightImg2,
    onClick: _cache[5] || (_cache[5] = (...args) => $options.toRightUrl2 && $options.toRightUrl2(...args))
  }, null, 8, _hoisted_8)) : _createCommentVNode("", true)])], 4);
}