// src/directives/clickFeedback.js
export default {
    beforeMount(el) {
      const addFeedback = () => {
        el.classList.add('click_active');
      };
  
      const removeFeedback = () => {
        el.classList.remove('click_active');
      };
  
      el.addEventListener('touchstart', addFeedback);
      el.addEventListener('touchend', removeFeedback);
      el.addEventListener('touchcancel', removeFeedback);
  
      el.addEventListener('mousedown', addFeedback);
      el.addEventListener('mouseup', removeFeedback);
      el.addEventListener('mouseleave', removeFeedback);
    },
  };
  